import React, { useState } from "react";
import { useAuth } from "../auth";
import Button from "../components/Button";
import TextInput from "../components/TextInput";
import useAlert from "../useAlert";

const PasswordRecovery: React.FC = () => {
    const auth = useAuth();
    const [email, setEmail] = useState("");
    const { addAlert } = useAlert();

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        auth.reset(email).then((resetStatus) => {
            if (resetStatus.error) {
                addAlert(resetStatus.error, "error");
                return;
            }
            addAlert("E-mail de reinicio de contraseña enviado", "success");
        });
    };

    return (
        <div className="h-screen w-full  font-sans">
            <div className="absolute top-6 left-8 sm:top-12 sm:left-12 w-40">
                <img src="/static/mokenapp/logo_grey.png" alt="service logo" />
            </div>
            <div className="sm:flex flex-row h-full">
                <div className="pt-16 sm:pt-0 sm:flex flex-col items-center justify-center w-full md:w-1/2 h-full bg-blue-light-4 px-8 ">
                    <div className="max-w-[500px]">
                        <p className="font-sans font-bold text-2xl text-blue">Restablecer contraseña</p>
                        <p className="font-sans font-thin mb-8 mr-24 text-blue">
                            Por favor, introduce el mail de registro de tu cuenta Moken y te enviaremos un enlace para
                            restablecer tu contraseña.
                        </p>
                        <form id="passwordrecoveryform" onSubmit={handleSubmit} className="w-full">
                            <div className="w-1/2 pb-4">
                                <TextInput
                                    id="email"
                                    type="email"
                                    placeholder="E-mail"
                                    onChange={(ev) => setEmail(ev.currentTarget.value)}
                                />
                            </div>
                            <hr className="mt-4 pb-8 border-blue-light-3" />

                            <Button type="btn_yellow" submit label="Restablecer contraseña" />
                        </form>
                        <p className="font-sans text-blue mt-8">
                            Volver a
                            <a className="text-yellow hover:text-yellow-dark-2 ml-2" href="/login">
                                Log in
                            </a>
                        </p>
                    </div>
                </div>
                <div className="hidden md:flex flex-col items-center justify-center w-1/2 h-full bg-gradient-to-t from-yellow-dark-2 to-yellow">
                    <div className="max-w-[500px] p-8">
                        <img src="/static/mokenapp/login_network.png" alt="requests" />
                        <p className="font-sans text-white font-bold text-2xl mt-12">Crea tu propia red de contactos</p>
                        <p className="font-sans text-white">
                            Conecta y trabaja de forma eficiente. Sincroniza tus propuestas con proveedores de tu red de
                            contactos dentro de Moken.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordRecovery;
