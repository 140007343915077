import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import StyleTheme from "./StyleTheme";
import Platform from "./screens/Platform";
import { AuthProvider } from "./auth";
import Login from "./screens/Login";
import Register from "./screens/Register";
import RegisterSuccess from "./screens/registerSuccess";
import PasswordRecovery from "./screens/PasswordRecovery";
import PasswordReset from "./screens/PasswordReset";
import { AlertProvider } from "./useAlert";
import Alert from "./components/Alert";
import { RefCacheProvider } from "./useModelRef";

function App(): React.ReactElement {
    return (
        <StyleTheme>
            <AuthProvider>
                <AlertProvider>
                    <RefCacheProvider>
                        <Router>
                            <Routes>
                                <Route path="/login" element={<Login />} />
                                <Route path="/registerSuccess" element={<RegisterSuccess />} />
                                <Route path="/register" element={<Register />} />
                                <Route path="/recovery" element={<PasswordRecovery />} />
                                <Route path="/reset" element={<PasswordReset />} />
                                <Route path="*" element={<Platform />} />
                            </Routes>
                        </Router>
                        <Alert />
                    </RefCacheProvider>
                </AlertProvider>
            </AuthProvider>
        </StyleTheme>
    );
}

export default App;
