import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import Button from "../components/Button";

const RegisterSuccess: React.FC = () => (
    <div className="h-screen w-full font-sans flex flex-col sm:items-center sm:justify-center">
        <div className="bg-yellow sm:flex flex-col sm:h-1/3 h-auto p-8 justify-end rounded sm:max-w-[520px] w-full">
            <div className="text-center flex justify-center">
                <span className="text-center text-yellow bg-white rounded w-[60px] h-[60px] flex justify-center items-center">
                    <CheckIcon sx={{ fontSize: "40px" }} />
                </span>
            </div>

            <h2 className="font-bold text-2xl text-center text-white mt-8">Woohooo!</h2>
            <p className=" mt-2 mb-4 text-center text-white">Ya eres miembro de Moken</p>
        </div>
        <div className="py-16 text-center">
            <a href="/login">
                <Button type="btn_yellow" label="Log in" title="log in" />
            </a>
        </div>
    </div>
);

export default RegisterSuccess;
